import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Seo from 'gatsby-theme-husky/src/common/Seo';
import PageSchema from 'gatsby-theme-husky/src/common/PageSchema';
import createBreadcrumbs from 'gatsby-theme-husky/src/utils/createBreadcrumbs';
import Layout from '../../layout/Layout';
import SoBanner from '../../components/SoBanner';
import { VeetMenPageProps } from './models';

import './VeetMenPageOverride.scss';

const VeetMenPage: FC<VeetMenPageProps> = ({
  data: {
    allUmbracoVeetMen: { nodes: umbracoVeetMen },
    allSitePage: { nodes },
    allBrandSettings,
  },
  pageContext: {
    breadcrumb: { crumbs },
    areBreadcrumbsDisplayed,
  },
  location: { pathname },
}) => {
  const {
    langProps: { urls, lang },
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs },
    content,
    colorExtraClass,
    pageLogo,
  } = umbracoVeetMen[0];
  const breadcrumbs = {
    breadCrumb: createBreadcrumbs(crumbs, areBreadcrumbsDisplayed, nodes, pathname),
  };
  const brandSettings = {
    ...allBrandSettings,
    nodes: allBrandSettings.nodes.map((node) => ({
      ...node,
      brandLogo: node.lang === lang ? pageLogo : node.brandLogo,
    })),
  };

  return (
    <Layout
      {...{ colorExtraClass }}
      breadcrumbs={breadcrumbs}
      langProps={{ urls, lang }}
      className="about-us-page"
      isVeetMen
      veetMeenHeader={{ brandSettings }}
    >
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      {content?.map(({ properties }, index) => (
        <div key={index}>
          <SoBanner data={properties} />
        </div>
      ))}
    </Layout>
  );
};

export const query = graphql`
  query VeetMenQuery {
    allUmbracoVeetMen {
      nodes {
        content {
          properties {
            containerVariant
            extraClass
            items {
              properties {
                variant
                extraClass
                bannerMain {
                  fallbackUrl
                  fluid {
                    srcSet
                    originalImg
                    base64
                  }
                  variants {
                    url
                    type
                    fallbackQuery
                    alias
                  }
                }
                bannerHalf {
                  fallbackUrl
                  fluid {
                    srcSet
                    originalImg
                    base64
                  }
                  variants {
                    url
                    type
                    fallbackQuery
                    alias
                  }
                }
                columnAlign
                image {
                  fallbackUrl
                  fluid {
                    srcSet
                    originalImg
                    base64
                  }
                  variants {
                    url
                    type
                    fallbackQuery
                    alias
                  }
                }
                coverVideo {
                  fallbackUrl
                  fluid {
                    srcSet
                    originalImg
                    base64
                  }
                  variants {
                    url
                    type
                    fallbackQuery
                    alias
                  }
                }
                video
                text
                legalText
              }
            }
          }
        }
        colorExtraClass
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
        }
        pageLogo {
          ...umbracoImage
          fallbackUrl
          variants {
            url
            type
            fallbackQuery
            alias
          }
        }
      }
    }
    allSitePage {
      ...FragmentAllPageSite
    }
    allUmbracoSearchResults {
      nodes {
        url
        langProps {
          lang
        }
      }
    }
    allBrandSettings {
      nodes {
        brandName
        url
        lang
        brandLogo {
          ...umbracoImage
        }
        customLogoRegex
        customBrandLogo {
          ...umbracoImage
        }
        videoTemplateBrandLogo {
          ...umbracoImage
        }
        videoTemplateBrandLogoBackground {
          ...umbracoImage
        }
      }
    }
    header {
      isHeaderTopVisible
    }
    socialMedia {
      socialMediaItems {
        properties {
          socialMediaIcon
          socialMediaAriaLabel
          isVisible
          socialMediaIconColor {
            label
            value
          }
          socialMediaHoverIconColor {
            label
            value
          }
          socialMediaLink {
            name
            target
            url
            icon
            published
          }
        }
      }
    }
  }
`;

export default VeetMenPage;
